import React, { useEffect, useState } from 'react';

import { 
    PageBackground, 
    pageGrid 
} from '../../stylesCSS';

import {  
    Grid,
    Typography
} from '@material-ui/core';

import { 
    getIntegrationRulesByBrand 
} from '../../services/integrationRules';

import { SomaGroupBrands } from "../../consts/brands";

import Footer from '../../components/Footer';
import IntegrationRuleStores from '../../components/IntegrationRuleStores';
import AutoCompleteChooseBrand from "../../components/AutoCompleteChooseBrand";

const SOMABRANDS = SomaGroupBrands.map((item) => {
    return {
      key: item.brandName,
      value: item.brandId,
    };
});

export default function IntegrationRulesDash() {
    const [integrationRules, setIntegrationRules] = useState([]);

    const [brand, setBrand] = React.useState(() => {
        const brandName = localStorage.getItem(`@maisomni:favoriteBrand`)
          ? localStorage.getItem(`@maisomni:favoriteBrand`)
          : "";
        const item = SOMABRANDS.find((item) => item.key === brandName);
        return item !== undefined ? item.value : "";
    });

    const [loading, setLoading] = useState(false);

    const getIntegrationRulesSellers = async (brandId) => {
        const response = await getIntegrationRulesByBrand(brandId);

        const transformData = response.map(item => {
            return {
                collectionsLength: item.collections ? item.collections.length : null,
                integrateProductsLength: item.integrateProducts ? item.integrateProducts.length : null,
                deleteProductsLength: item.deleteProducts ? item.deleteProducts.length : null,
                ...item
            }
        })

        setIntegrationRules(transformData);
    };

    useEffect(() => {
            (async () => {
                setLoading(true);
                await Promise.all([
                    getIntegrationRulesSellers(brand),
                ]);
                setLoading(false);
            })();
    }, [brand]);

    return(
        <>
            <div style={PageBackground}>
                <div style={pageGrid}>
                    <Grid container alignItems="center" justify="space-between" style={{ paddingTop: '20px' }}>
                        <Grid item style={{ width: '300px' }}>
                            <AutoCompleteChooseBrand
                                title={"Marca"}
                                itemsToChoose={SOMABRANDS}
                                brand={brand}
                                setBrand={setBrand}
                                storageName={"@maisomni:favoriteBrand"}
                            />
                        </Grid>
                    </Grid>
                
                    <Grid container>
                        <Typography 
                            style={{ 
                                fontWeight: 600,
                                fontSize: 30,
                                color: '#fff',
                                padding: '30px 0'
                            }}
                        >
                            Regras de integração
                        </Typography>
                    </Grid>
                    
                    <Grid container style={{ width: '100%' }} alignItems="center">
                        <IntegrationRuleStores
                            loading={loading} 
                            storeData={integrationRules}
                        />
                    </Grid>
                </div>
                <Footer />
            </div>
        </>
    );
}